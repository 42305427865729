import {Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'
import { ToastContainer } from 'react-toastify';

const PesquisaSatisfacao                   = lazy(() => import('../../Pages/PesquisaSatisfacao'));

const AppMain = () => {

    return (
        <Fragment>
            
            {/* Pesquisa Satisfacao */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/atendimento" component={PesquisaSatisfacao} />
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/atendimento" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;