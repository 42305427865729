import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

import AppMain from '../../Layout/AppMain';

import Loader from 'react-loader-advanced';
import { Loader as LoaderAnim } from 'react-loaders'

// CSS 
import '../../css/index.css';

//Contexto Geral
import ContextMain from './ContextMain';

class Main extends React.Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            closedSmallerSidebar: false,

            dadosUsuario: {},
            setDadosUsuario: this.setDadosUsuario,

            dadosFiltroUsuario: {},
            setDadosFiltroUsuario: this.setDadosFiltroUsuario,

            dadosUsuarioSelecionado: { },
            setDadosUsuarioSelecionado: this.setDadosUsuarioSelecionado,

            listaUsuario: [],
            setListaUsuario: this.setListaUsuario,

            dadosGrupo: {},
            setDadosGrupo: this.setDadosGrupo,

            listaGrupo: [],
            setListaGrupo: this.setListaGrupo,

            dados: {},
            setDados: this.setDados,
        };
    }

    setDados = (dados) => {
        this.setState({ dados });
    };

    setDadosUsuario = (dadosUsuario) => {
        this.setState({ dadosUsuario });
    }

    setDadosFiltroUsuario = (dadosFiltroUsuario) => {
        this.setState({ dadosFiltroUsuario });
    }

    setDadosUsuarioSelecionado = (dadosUsuarioSelecionado) => { 
        this.setState({ dadosUsuarioSelecionado });
    };

    setListaUsuario = (listaUsuario) => {
        this.setState({ listaUsuario });
    }

    setDadosGrupo = (dadosGrupo) => {
        this.setState({ dadosGrupo });
    }

    setListaGrupo = (listaGrupo) => {
        this.setState({ listaGrupo });
    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
            enableLoaderShow
        } = this.props;

        return (
            <ContextMain.Provider value={this.state}>
                <ResizeDetector
                    handleWidth
                    render={({ width }) => (
                        <Loader
                            message={<LoaderAnim color="#ffffff" type="ball-grid-beat"/>}
                            messageStyle={{ display: 'block', verticalAlign: 'unset', marginTop: '50vh' }}
                            foregroundStyle={{ height: '100%', width: '100%' }}
                            backgroundStyle={{  height: '100%', width: '100%' }}
                            show={enableLoaderShow}>
                                <Fragment>
                                    <div className={cx(
                                        "app-container app-theme-" + colorScheme,
                                        { 'fixed-header': enableFixedHeader },
                                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                                        { 'fixed-footer': enableFixedFooter },
                                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                                        { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                                        { 'sidebar-mobile-open': enableMobileMenu },
                                        { 'body-tabs-shadow-btn': enablePageTabsAlt },
                                    )}>
                                        <AppMain />
                                        
                                    </div>
                                    
                                </Fragment>
                        </Loader>
                    )}
                />
            </ContextMain.Provider>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

    enableLoaderShow: state.ThemeOptions.enableLoaderShow
});

export default withRouter(connect(mapStateToProp)(Main));